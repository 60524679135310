<template>
  <div class="card-grid-container">
    <div
      v-if="blok.SectionTitleText && blok.SectionTitleText[0]"
      v-editable="blok"
      class="layout-container"
    >
      <CustomRTE
        v-if="blok.SectionTitleText"
        class="card__text"
        :blok="blok.SectionTitleText[0]"
      />
    </div>
    <div
      v-editable="blok"
      class="card-grid"
      :class="[addOffset && 'card-grid--add-offset']"
      :style="`--grid-column-count: ${blok.column_count};`"
      :data-column-count="blok.column_count"
    >
      <div v-for="card in blok.cards" :key="card._uid" class="card-grid__card">
        <Card :blok="card" />
      </div>
    </div>
    <div
      v-if="blok.ctas && blok.ctas.length > 0"
      class="card-grid-ctas layout-container"
    >
      <CTAGroup :ctas="blok.ctas" />
    </div>
    <div
      v-if="blok.legalText && blok.legalText.valueOf().trim() !== ''"
      class="layout-container"
    >
      <p class="legal-text mt-8">
        {{ blok.legalText }}
      </p>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { CardBlokProps, CardGridBlokProps } from '@/types';

// Props
const props = defineProps<{
  blok: CardGridBlokProps;
}>();

const addOffset = computed(
  () =>
    props.blok.cards.every(
      (c: CardBlokProps) =>
        c.media && c.media[0] && c.media[0].component === 'LayeredPicture'
    ) && props.blok.column_count === '2'
);
</script>

<style lang="scss">
.card-grid {
  --column-count: var(--grid-column-count);

  display: grid;
  grid-gap: var(--spacing-xl) columns(1);
  grid-template-columns: repeat(var(--column-count), 1fr);

  &--add-offset {
    display: flex;
    flex-flow: row wrap;
    gap: var(--spacing-xl) columns(2);
    justify-content: center;

    .card-grid__card {
      flex-basis: 100%;
      max-width: columns(9);

      &:nth-child(even) {
        margin-block-start: var(--spacing-xxl);
      }
    }
  }

  @media all and #{$mq-m-max} {
    &[data-column-count='4'] {
      --column-count: 3;
    }

    &[data-column-count='3'] {
      --column-count: 2;
    }
  }

  @media all and #{$mq-s-max} {
    &[data-column-count='4'] {
      --column-count: 2;
    }

    &[data-column-count='2'] {
      --column-count: 1;
      &.card-grid--add-offset {
        .card-grid__card {
          margin-block-start: 0;
          max-width: none;
        }
      }
    }
  }

  @media all and #{$mq-xs-max} {
    &[data-column-count='4'],
    &[data-column-count='3'] {
      --column-count: 1;
    }
  }
}

.layout-container {
  padding-bottom: var(--spacing-l);
}

.card-grid-container {
  @include container;
  .card-grid-ctas {
    padding-top: var(--spacing-l);
    .cta {
      width: 100%;
      display: block;
    }

    @media all and #{$mq-m-min} {
      display: none;
    }
  }
}

.legal-text {
  font-size: 16px;
  text-align: center;
  color: var(--neutral-2);
}
</style>
